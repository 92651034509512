import ArticleList from 'components/Articles/primitives/ArticleList';
import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import SectionHeader from 'primitives/Typography/Headings/SectionHeader';
import styled from '@emotion/styled';

const GenreSectionWrapper = styled('div')(({ theme }) => ({
  borderTop: `1px solid ${theme.colors.gray['300']}`,
  padding: '2rem 0',
  display: 'inline-block',
  verticalAlign: 'top',
  width: '50%',
  '&:nth-of-type(-n+2)': {
    // slect the first two
    borderTopWidth: '0',
    paddingTop: '0',
  },

  [mediaQueryBuilder(theme.mediaQueries.max.width['768'])]: {
    padding: 0,
    width: '100%',
    '&:nth-of-type(2)': {
      borderTopWidth: '1px',
    },
    '.section-block': {
      padding: 0,
      '&:last-of-child': {
        padding: 0,
      },
    },
    [SectionHeader.toString()]: {
      paddingBottom: '0',
      a: {
        display: 'inline-block',
        fontSize: '1.6rem',
        fontWeight: 'normal',
        lineHeight: '2.16rem',
        paddingBottom: '1.5rem',
        paddingTop: '1.5rem',
        width: '100%',
      },
    },
    [ArticleList.toString()]: {
      display: 'none',
    },
  },
}));

export default GenreSectionWrapper;
