import GenericHead from 'components/GenericHead';
import useTranslate from 'contexts/TranslateContext/useTranslate';
import { Helmet } from 'react-helmet';
import { translateKeywords } from 'utils/metaUtils';

function Head() {
  const translate = useTranslate();

  const keywords = translateKeywords(
    translate,
    'Genre, Format, Live, Custom, Artist, Stations, Stream, Online, Free, iHeartRadio, iHeart',
  );
  const description = translate(
    'Finding your ideal station is quick and easy. Search for stations by genre & stream them online for free on iHeart.',
  );
  const title = translate(
    'Discover Music and Radio Stations by Genre on iHeart',
  );

  return (
    <>
      <GenericHead
        metaDescription={description}
        ogType="music.radio_station"
        title={title}
      />
      <Helmet meta={[{ content: keywords, name: 'keywords' }]} />
    </>
  );
}

export default Head;
