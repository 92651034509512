import analytics, { Events } from './index';
import trackers from 'trackers';
import useMount from 'hooks/useMount';
import { useCallback, useEffect, useRef } from 'react';
import type { Data as PageViewData } from './helpers/pageView';

export type Lifecycle = boolean | (() => boolean);

export type Options = {
  trackOnDidUpdate?: Lifecycle;
  trackOnDidMount?: Lifecycle;
};

/**
 * A hook counterpart to the `withAnalytics`
 * Pass PageViewData as the first param and lifecycle conditions
 * as the second to control when the analytics pageView event will be tracked
 */
function usePageView(
  pageViewData: PageViewData,
  { trackOnDidUpdate = false, trackOnDidMount = true }: Options = {},
) {
  const initializedRef = useRef(false);

  const shouldTrack = useCallback(
    (lifecycle: Lifecycle) =>
      __CLIENT__ &&
      !!(typeof lifecycle === 'function' ? lifecycle() : lifecycle),
    [],
  );

  const track = useCallback(() => {
    analytics.trackPageView?.(pageViewData);
    trackers.track(Events.PageView, pageViewData);
  }, [pageViewData]);

  useMount(() => {
    if (shouldTrack(trackOnDidMount)) track();
    initializedRef.current = true;
  });

  useEffect(() => {
    if (initializedRef.current && shouldTrack(trackOnDidUpdate)) track();
    // We should only listen to changes to the pageViewData via the track method
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [track]);

  return track;
}

export default usePageView;
